import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/header/seo"
import LinkWrapper from "../components/reusable-components/link-wrapper"
import ContactForm from "../components/contact-us-page/contact-form"

import PhoneSvg from "../images/icons/phone"
import MailSvg from "../images/icons/mail"
import LocationSvg from "../images/icons/icon-feather-map-pin"

import { getProperty, slugify, replaceAll } from "../lib/utils"

const ContactUsPage = ({ location, pathContext }) => {
  const contactUsPageQueryData = useStaticQuery(CONTACT_US_PAGE_QUERY)
  const title = getProperty(contactUsPageQueryData, ["wpPage", "title"], "")
  const contactTitle = getProperty(
    contactUsPageQueryData,
    ["wpPage", "acf_contact_us_section", "contactFormTitle"],
    ""
  )
  const contactSubtitle = getProperty(
    contactUsPageQueryData,
    ["wpPage", "acf_contact_us_section", "contactFormSubtitle"],
    ""
  )
  const serviceTitle = getProperty(
    contactUsPageQueryData,
    ["wpPage", "acf_contact_us_section", "serviceFormTitle"],
    ""
  )
  const serviceSubtitle = getProperty(
    contactUsPageQueryData,
    ["wpPage", "acf_contact_us_section", "serviceFormSubtitle"],
    ""
  )
  const branches = getProperty(
    contactUsPageQueryData,
    ["wpPage", "acf_contact_us_section", "branches"],
    []
  )
  branches.sort((a, b) => {
    const titleA = a.title
    const titleB = b.title
    if (titleA < titleB) {
      return -1
    }
    if (titleA > titleB) {
      return 1
    }
    return 0
  })

  return (
    <Layout pathContext={pathContext} location={location}>
      <SEO
        title={getProperty(
          contactUsPageQueryData,
          ["seaPage", "seo", "title"],
          ""
        )}
        location={location}
        yoast={getProperty(contactUsPageQueryData, ["seaPage", "seo"], {})}
      />
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <ContactUsTopContentStyling>
        <BranchesSectionStyling>
          {branches.map((branch, branch_i) => {
            const name = getProperty(branch, ["branchName"], "")
            const phone = getProperty(branch, ["branchTelephone"], "")
            const mail = getProperty(branch, ["branchEmail"], "")
            const address = getProperty(branch, ["branchAddress"], "")
            const addressLink = getProperty(branch, ["branchAddressLink"], "")
            return !name ? null : (
              <BranchBoxStyling key={branch_i}>
                <LinkWrapper to={`/contact-us/${slugify(name)}/`}>
                  <h4 dangerouslySetInnerHTML={{ __html: name }} />
                </LinkWrapper>
                {!phone ? null : (
                  <BranchInfoRowStyling>
                    <PhoneSvg />
                    <a href={`tel:${replaceAll(phone.split("/")[0], " ", "")}`}>
                      {phone}
                    </a>
                  </BranchInfoRowStyling>
                )}
                {!mail ? null : (
                  <BranchInfoRowStyling>
                    <MailSvg />
                    <a href={`mailto:${mail}`}>{mail}</a>
                  </BranchInfoRowStyling>
                )}
                {!address ? null : (
                  <BranchInfoRowStyling>
                    <LocationSvg />
                    <a
                      href={addressLink || "#"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {address}
                    </a>
                  </BranchInfoRowStyling>
                )}
              </BranchBoxStyling>
            )
          })}
        </BranchesSectionStyling>
        <ContactForm
          location={location}
          contactTitle={contactTitle}
          contactSubtitle={contactSubtitle}
          formName="contact"
        />
      </ContactUsTopContentStyling>
      <ContactForm
        location={location}
        contactTitle={serviceTitle}
        contactSubtitle={serviceSubtitle}
        formName="service"
      />
    </Layout>
  )
}

export default ContactUsPage

// ===============
//     STYLES
// ===============

const ContactUsTopContentStyling = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 6rem auto;
`
const BranchInfoRowStyling = styled.div`
  svg {
    width: 15px;
    height: 15px;
    stroke: ${({ theme }) => theme.colors.blue};
    vertical-align: top;
    margin-top: 0.5rem;
  }
  a {
    padding-left: 30px;
    display: inline-block;
    width: calc(100% - 15px);
    vertical-align: top;
  }
  &:not(:last-child) a {
    padding-bottom: 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    a {
      padding-left: 10px;
      padding-bottom: 10px;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    a {
      padding-left: 20px;
    }
    &:last-child a {
      padding-bottom: 0;
    }
  }
`
const BranchBoxStyling = styled.div`
  background-color: ${({ theme }) => theme.colors.lightgrey1};
  display: block;
  padding: 30px;
  margin-bottom: 20px;
  h4 {
    padding-bottom: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: 32%;
    width: 100%;
    padding: 15px;
    margin-bottom: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    max-width: 100%;
    width: 100%;
    padding: 30px;
    margin-bottom: 20px;
  }
`
const BranchesSectionStyling = styled.div`
  max-width: 400px;
  width: 100%;
  margin-right: 20px;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-right: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    flex-direction: column;
    justify-content: space-between;
  }
`

// ===============
//      QUERY
// ===============
const CONTACT_US_PAGE_QUERY = graphql`
  {
    wpPage(slug: { eq: "contact-us" }) {
      title
      acf_contact_us_section {
        contactFormTitle
        contactFormSubtitle
        serviceFormTitle
        serviceFormSubtitle
        branches {
          branchAddress
          branchAddressLink
          branchEmail
          branchMapLink
          branchName
          fieldGroupName
          branchTelephone
        }
      }
    }
    seaPage(slug: { eq: "contact-us" }) {
      seo {
        description
        og_description
        og_image
        og_title
        og_type
        site_name
        title
        twitter_card
        twitter_creator
        twitter_description
        twitter_title
      }
    }
  }
`
